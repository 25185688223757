import { Link } from "gatsby";
import React from "react";

const Header = () => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        textAlign: `center`,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            textDecoration: `none`,
            fontFamily: "Georgia",
            fontWeight: "normal",
            color: "black",
          }}
        >
          Henri de Haas
        </Link>
      </h1>
    </div>
  </header>
);

export default Header;
