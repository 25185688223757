import React, { ReactNode } from "react";
import Header from "./header";
import "./layout.css";

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 40px`,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
        <footer style={{ textAlign: "center", fontSize: "12px" }}>
          © {new Date().getFullYear()} Henri de Haas
        </footer>
      </div>
    </>
  );
};

export default Layout;
